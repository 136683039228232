// src/pages/content/tours/redux/saga.js
import { call, put, all, takeLatest } from "redux-saga/effects";
import {
    searchTour,
    getTourByName,
    getTourPrices,
    getTourFinalPrice,
    getPaymentOptions,
    bookTour,
    getTours,
    getTourBySubCategoryName,
    getTourByCode
} from "../../../../services/tour.service";
import {
    commonRequestAction,
    commonSuccessAction,
    commonErrorAction,
    setListData,
} from "../../../redux/actions";
import { BOOK_TOUR, GET_PAYMENT_OPTIONS, GET_TOURS, GET_TOUR_BY_CODE, GET_TOUR_BY_SUBCATEGORY_NAME, GET_TOUR_BY_NAME, GET_TOUR_FINAL_PRICE, GET_TOUR_PRICES, SEARCH_TOUR } from "./constants";
import { setTourDataAction } from "./actions";



function* getToursSaga({ queryParams }) {
    try {
        const response = yield call(getTours, queryParams);
        if (response.status === 200) {
            yield put(setTourDataAction(response.data?.data));
            // yield put(setListData(response.data?.data));
        }
    } catch (error) {
        console.log("error", error?.response?.data?.message)
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* searchTourSaga({ body, callback }) {
    try {
        const response = yield call(searchTour, body);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        console.log("error", error?.response?.data?.message)
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getTourByNameSaga({ name, callback }) {
    try {
        const response = yield call(getTourByName, name);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        console.log("error", error?.response?.data?.message)
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getTourByCodeSaga({ code, callback }) {
    try {
        const response = yield call(getTourByCode, code);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getTourBySubCategoryNameSaga({ params, callback }) {
    try {
        const response = yield call(getTourBySubCategoryName, params);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}


function* getTourPricesSaga({ body, callback }) {
    try {
        const response = yield call(getTourPrices, body);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
            if (callback) {
                callback(response.data.data)
            }
        }
    } catch (error) {
        yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getTourFinalPriceSaga({ body }) {
    try {
        const response = yield call(getTourFinalPrice, body);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
        }
    } catch (error) {
        yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getPaymentOptionsSaga({ body }) {
    try {
        const response = yield call(getPaymentOptions, body);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
        }
    } catch (error) {
        yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* bookTourSaga({ body, callback }) {
    try {
        const response = yield call(bookTour, body);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
            if (callback) {
                callback(response.data.data);
            }
        }
    } catch (error) {
        yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(SEARCH_TOUR, searchTourSaga),
    takeLatest(GET_TOURS, getToursSaga),
    takeLatest(GET_TOUR_BY_NAME, getTourByNameSaga),
    takeLatest(GET_TOUR_BY_CODE, getTourByCodeSaga),
    takeLatest(GET_TOUR_BY_SUBCATEGORY_NAME, getTourBySubCategoryNameSaga),
    takeLatest(GET_TOUR_PRICES, getTourPricesSaga),
    takeLatest(GET_TOUR_FINAL_PRICE, getTourFinalPriceSaga),
    takeLatest(GET_PAYMENT_OPTIONS, getPaymentOptionsSaga),
    takeLatest(BOOK_TOUR, bookTourSaga),
]);