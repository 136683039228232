import React from 'react'
import { Col, Container, Grid, Heading, IconButton, Row } from 'rsuite'
import './footer_case.scss';
import { CustomText } from '../CustomComponents';
import { Link } from 'react-router-dom';
import { FaTelegram } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";


const Footer = (props) => {
    return (
        <Grid fluid className='footer'>
            <Row className='footer_row'>
                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Heading level={6}>{"Hakkımızda"}</Heading>
                    <Link to="/about"><CustomText value={"Hakkımızda"} weight={"bold"} /></Link>
                    <Link to="/kvvk"><CustomText value={"KVVK"} weight={"bold"} /></Link>
                    <Link to="/privacy-police"> <CustomText value={"Gizlilik Politikası"} weight={"bold"} /></Link>
                    {/* <Link to="/about"><CustomText value={"Hizmet Kalitemiz"} weight={"bold"} /></Link> */}
                    <Link to="/communication"><CustomText value={"Kurumsal iletişim"} weight={"bold"} /></Link>
                </Col>
                {/* <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Heading level={6}>{"Popular Seçenekler"}</Heading>
                    <Link to="Kültür Turları"><CustomText value={"Kültür Turları"} weight={"bold"} /></Link>
                    <Link to="Kapadokya Turları"><CustomText value={"Kapadokya Turları"} weight={"bold"} /></Link>
                    <Link to="Yalova Termal Otelleri"> <CustomText value={"Yalova Termal Otelleri"} weight={"bold"} /></Link>
                    <Link to="Eğitim"><CustomText value={"Eğitim"} weight={"bold"} /></Link>
                    <Link to="Yurtiçi Oteller"><CustomText value={"Yurtiçi Oteller"} weight={"bold"} /></Link>
                </Col> */}

                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Heading level={6}>{"Şartlar"}</Heading>
                    <Link to="Kullanıcı Sözleşmesi"><CustomText value={"Kullanıcı Sözleşmesi"} weight={"bold"} /></Link>
                    <Link to="Gizlilik Sözleşmesi"><CustomText value={"Gizlilik Sözleşmesi"} weight={"bold"} /></Link>
                    <Link to="Tur/Otel Satış Sözleşmes"> <CustomText value={"Tur/Otel Satış Sözleşmesi"} weight={"bold"} /></Link>
                    <Link to="Kişisel Verilerin Korunması"><CustomText value={"Kişisel Verilerin Korunması"} weight={"bold"} /></Link>
                </Col>
                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Heading level={6}>{"Takip"}</Heading>
                    <Link to="https://t.me/hamza_95_T" target='_blank'><IconButton icon={<FaTelegram />} /></Link>
                    <Link to="https://www.instagram.com/yesil_pasaportlular/" target='_blank'><IconButton icon={<RiInstagramFill />} /></Link>
                    <Link to="https://www.facebook.com/" target='_blank'><IconButton icon={<FaFacebook />} /></Link>
                </Col>
            </Row>
        </Grid>
    )
}

export default Footer