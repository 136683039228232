import { authToken, instance, setParams } from "../utils/xhr";
import { SUB_CATEGORY_URL } from "./constants";


export const getSubCategories = async (category_id) => {
    return await instance.get(`${SUB_CATEGORY_URL}/${category_id}`);
};

export const getSubCategoryByName = async (name) => {
    return await instance.get(`${SUB_CATEGORY_URL}/detail`, setParams({ name }));
};
