import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import './navbar_case.scss'
import { getStorageItem, setStorageItem } from '../../utils/helpers'
import { Accordion, Affix, Avatar, Button, Col, Drawer, Dropdown, IconButton, Nav, Navbar, Popover, Row, Stack, Whisper } from 'rsuite'
import { NavMenu } from '../CustomComponents'
import { MdOutlineModeOfTravel } from "react-icons/md";
import { FaHotel } from "react-icons/fa6";
import { MdTour } from "react-icons/md";
import { MdOutlineCastForEducation } from "react-icons/md";
import logo from "../../assets/logo.png";
import { hotels, tours, transfers } from '../../data/demo';
import MenuIcon from '@rsuite/icons/Menu';
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from '../../utils/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import { connect } from 'react-redux'
import { getCategoriesAction } from '../../pages/content/category/redux/actions'
import { logoutAction } from '../../pages/redux/actions'
import Cookies from 'js-cookie'
import { MdOutlineHealthAndSafety } from "react-icons/md";


const renderMenu = ({ onClose, left, top, className }, ref) => {
  const handleSelect = eventKey => {
    onClose();
    setStorageItem("lang", eventKey);
    window.location.reload();
  };
  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu onSelect={handleSelect}>
        {LANGUAGES.map((el, ind) => (
          <Dropdown.Item eventKey={el} key={ind} icon={<Avatar circle src={el.flag} style={{ maxHeight: '30px', maxWidth: '30px' }} />}>&nbsp;{el.name}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Popover>
  );
};

const userRenderMenu = (user, t, logoutAction) => ({ onClose, left, top, className }, ref) => {
  const handleSelect = eventKey => {
    onClose();
    if (eventKey === "logout") {
      logoutAction(Cookies.get("twj"));
    } else if (eventKey === "/login") {
      window.location.replace(eventKey);
    }
  };

  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu onSelect={handleSelect}>
        {user?.fullName ? (
          <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
            <strong>{user?.fullName}</strong>
          </Dropdown.Item>
        ) : (
          <Dropdown.Item eventKey={"/login"}>{t('common.login')}</Dropdown.Item>
        )}
        <Dropdown.Separator />
        <Dropdown.Item>Your profile</Dropdown.Item>
        <Dropdown.Item eventKey={"logout"}>{t('common.logout')}</Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );
};

const Header = props => {
  const { Icon, title, subtitle, ...rest } = props;
  return (
    <Stack {...rest} spacing={10} alignItems="flex-start">
      {Icon}
      <Stack spacing={2} direction="column" alignItems="flex-start">
        <div>{title}</div>
        <div style={{ color: 'var(--rs-text-secondary)', fontSize: 12 }}>{subtitle}</div>
      </Stack>
    </Stack>
  );
};

const NavBar = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleClick = (value) => {
    if (value) {
      navigate(value);
    }
  }
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    props.getCategoriesAction(callback);
  }, []);

  const callback = useCallback((data) => {
    if (data) {
      // Can be come Category data or Sub Category data. TODO: some logic
    }
  }, [])

  return (
    <React.Fragment>
      <Navbar className={props.idForScroll ? "" : `navbar ${isScrolled ? 'scrolled' : ''}`} id={props.idForScroll}>
        <Row className='main_navbar'>
          <Col xl={8} lg={8} md={12} xs={12}>
            <Navbar.Brand href='/' style={{ padding: 0 }}>
              <img alt='logo' src={logo} style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%' }} />
            </Navbar.Brand>
          </Col>
          <Col xl={16} lg={16} md={12} xs={12} style={{ justifyContent: 'end', alignItems: 'center' }}>
            {/* lang */}
            <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu}>
              <Avatar circle src={getStorageItem("lang")?.flag} style={{ maxWidth: '30px', maxHeight: '30px', cursor: 'pointer' }} />
            </Whisper>

            {/* responsive nav menu */}
            <IconButton appearance='link' icon={<MenuIcon />} onClick={() => setOpen(true)} className='main_appbar_drpdwn' />

            {/* nav menu */}
            <Nav className='main_appbar_menu'>
              <Nav.Item icon={<MdOutlineHealthAndSafety />} onClick={() => handleClick("SSS")}>SSS</Nav.Item>
              <NavMenu title={t('nav_bar.tour')} items={props.categories_data?.data} icon={<MdTour />} eventKey="/tours" handleClick={handleClick} />
              <NavMenu title={t('nav_bar.hotel')} items={t('common.in_progress')} icon={<FaHotel />} eventKey="/hotels" handleClick={handleClick} />
              <NavMenu title={t('nav_bar.transfer')} items={t('common.in_progress')} icon={<MdOutlineModeOfTravel />} eventKey="/transfers" handleClick={handleClick} />
              <NavMenu title={t('nav_bar.education')} items={t('common.in_progress')} icon={<MdOutlineCastForEducation />} eventKey="/education" handleClick={handleClick} />
            </Nav>

            {/* user */}
            <Whisper placement="bottomEnd" trigger="click" speaker={userRenderMenu(props.user, t, props.logoutAction)}>
              <Avatar circle src={props.user?.thumbnail ?? ""} style={{ maxWidth: '30px', maxHeight: '30px', cursor: 'pointer' }} />
            </Whisper>
          </Col>
        </Row>
      </Navbar>
      <Drawer open={open} onClose={() => setOpen(false)} size={"full"}>
        <Drawer.Header>
          <Drawer.Title>{t("common.menu")}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpen(false)}>{t("common.close")}</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{ padding: '30px' }}>
          <Accordion bordered defaultActiveKey={1}>
            <div style={{ padding: '20px 10px 0 10px', display: 'flex' }}>
              <Button onClick={() => [handleClick("/SSS"), setOpen(false)]} appearance="subtle"
                style={{
                  width: '100%', alignItems: 'start', justifyContent: 'start',
                  fontSize: '16px', fontWeight: 800
                }} startIcon={<MdOutlineHealthAndSafety />}>SSS</Button>
            </div>
            {/* tours */}
            <Accordion.Panel
              header={
                <Header
                  Icon={<MdTour />}
                  title={t('nav_bar.tour')}
                />
              }
            >
              {tours?.map((val, ind) => (
                <Accordion.Panel
                  key={ind}
                  header={
                    <Header
                      title={val?.name}
                    />
                  }
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {val?.data?.map((item, index) => (
                      <Button key={index} onClick={() => [handleClick(`/tours/${val?.name}/${item?.title}`), setOpen(false)]} style={{ width: 'fit-content' }} endIcon={<ArrowRightLineIcon />}>{item?.title}</Button>
                    ))}
                  </div>
                </Accordion.Panel>
              ))}
            </Accordion.Panel>

            {/* hotels */}
            <Accordion.Panel
              header={
                <Header
                  Icon={<FaHotel />}
                  title={t('nav_bar.hotel')}
                />
              }
            >
              {hotels?.map((val, index) => (
                <Accordion.Panel
                  key={index}
                  header={
                    <Header
                      title={val?.name}
                    />
                  }
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {val?.data?.map((item, key) => (
                      <Button key={key} onClick={() => [handleClick(`/hotels/${val?.name}/${item?.title}`), setOpen(false)]} style={{ width: 'fit-content' }} endIcon={<ArrowRightLineIcon />}>{item?.title}</Button>
                    ))}
                  </div>
                </Accordion.Panel>
              ))}
            </Accordion.Panel>

            {/* transfers */}
            <Accordion.Panel
              header={
                <Header
                  Icon={<FaHotel />}
                  title={t('nav_bar.transfer')}
                />
              }
            >
              {transfers?.map((val, index) => (
                <Accordion.Panel
                  key={index}
                  header={
                    <Header
                      title={val?.name}
                    />
                  }
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {val?.data?.map((item, key) => (
                      <Button key={key} onClick={() => [handleClick(`/transfers/${val?.name}/${item?.title}`), setOpen(false)]} style={{ width: 'fit-content' }} endIcon={<ArrowRightLineIcon />}>{item?.title}</Button>
                    ))}
                  </div>
                </Accordion.Panel>
              ))}
            </Accordion.Panel>

            {/* education */}
            <Accordion.Panel
              header={
                <Header
                  Icon={<MdOutlineCastForEducation />}
                  title={t('nav_bar.education')}
                />
              }
            >
              {transfers?.map((val, index) => (
                <Accordion.Panel
                  key={index}
                  header={
                    <Header
                      title={val?.name}
                    />
                  }
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {val?.data?.map((item, key) => (
                      <Button key={key} onClick={() => [handleClick(`/transfers/${val?.name}/${item?.title}`), setOpen(false)]} style={{ width: 'fit-content' }} endIcon={<ArrowRightLineIcon />}>{item?.title}</Button>
                    ))}
                  </div>
                </Accordion.Panel>
              ))}
            </Accordion.Panel>
          </Accordion>
        </Drawer.Body>
      </Drawer>
    </React.Fragment >
  )
}

const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
  categories_data: state.categoryReducer.categories_data,
  user: state.commonReducer.user
});

const mapDispatchToProps = dispatch => ({
  getCategoriesAction: (callback) => dispatch(getCategoriesAction(callback)),
  logoutAction: (token) => dispatch(logoutAction(token))

});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)