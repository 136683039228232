import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, ButtonToolbar, Container, FlexboxGrid, Form, Input, InputGroup, Panel, Tooltip, Whisper } from 'rsuite';
import AvatarIcon from '@rsuite/icons/legacy/Avatar';
import { CustomInput } from '../../components/CustomComponents';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { connect } from 'react-redux';
import { loginAction } from '../redux/actions';
import './login_case.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
  const {
    handleSubmit,
    control, reset, setValue, getValues,
    formState: { errors }
  } = useForm();

  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const handleChange = () => {
    setVisible(!visible);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("username", data?.username);
    formData.append("password", data?.password);

    props.loginAction(formData, callback);
  }
  const callback = useCallback((value) => {
    if (value) {
      navigate(-1);
    }
  }, []);

  return (
    <FlexboxGrid justify="center" className="auth">
      <FlexboxGrid.Item colspan={10} style={{ width: '100%' }}>
        <Panel header={<strong style={{ fontSize: '32px' }}>Login</strong>} bordered>
          <Form fluid onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="username"
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <CustomInput
                  title={"Kullanıcı adı veya E-posta"}
                  error={errors[field?.name]?.message}
                  children={
                    <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                      <InputGroup inside>
                        <Input value={field?.value} onChange={field?.onChange} />
                        <InputGroup.Addon>
                          <AvatarIcon />
                        </InputGroup.Addon>
                      </InputGroup>
                    </Whisper>
                  }
                />
              )}
              rules={{ required: t('common.required') }}
            />
            <Controller
              name="password"
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <CustomInput
                  title={"Şifre"}
                  error={errors[field?.name]?.message}
                  children={
                    <Whisper trigger="focus" speaker={<Tooltip>Gerekli</Tooltip>}>
                      <InputGroup inside>
                        <Input type={visible ? 'text' : 'password'} value={field?.value} onChange={field?.onChange} />
                        <InputGroup.Button onClick={handleChange}>
                          {visible ? <EyeIcon /> : <EyeSlashIcon />}
                        </InputGroup.Button>
                      </InputGroup>
                    </Whisper>
                  }
                />
              )}
              rules={{ required: t('common.required') }}
            />
            <Form.Group>
              <ButtonToolbar>
                <Button appearance="primary" type='submit'>Giriş yap</Button>
                <Button appearance="link" href={`https://wa.me/05454919594?text=Şifreyi%20unutdum%20${getValues("email")}`}>Şifreyi unutdum?</Button>
                <Button appearance="link" href={'/signup'}>Üye ol</Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}
const mapStateToProps = state => ({
  common_requesting: state.commonReducer.common_requesting,
});

const mapDispatchToProps = dispatch => ({
  loginAction: (body, callback) => dispatch(loginAction(body, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);