export const LOGIN = '/user/login';
export const SIGNUP = '/signup';
export const LOGOUT = '/logout';

//Tours
export const TOUR_URL = '/tours';
export const GET_TOUR_BY_NAME_URL = 'Tour.svc/Rest/Json/GetTourDetails';
export const GET_PAYMENT_OPTIONS_URL = 'Tour.svc/Rest/Json/GetPaymentOptions';
export const GET_TOUR_FINAL_PRICE_URL = 'Tour.svc/Rest/Json/GetTourFinalPrice';
export const BOOK_TOUR_URL = 'Tour.svc/Rest/Json/BookTour';

//Categories
export const CATEGORY_URL = '/categories';

//Sub Categories
export const SUB_CATEGORY_URL = '/sub-categories';