import { authToken, instance, setParams } from "../utils/xhr";
import { BOOK_TOUR_URL, GET_PAYMENT_OPTIONS_URL, GET_TOUR_BY_NAME_URL, GET_TOUR_FINAL_PRICE_URL, TOUR_URL } from "./constants";


export const searchTour = async (body) => {
    return await instance.post(`${TOUR_URL}/search`, body);
};

export const getTours = async (queryParams) => {
    return await instance.get(TOUR_URL, setParams({ ...queryParams }));
};

export const getTourByName = async (name) => {
    return await instance.get(`${TOUR_URL}/detail`, setParams({ name }));
};

export const getTourBySubCategoryName = async (params) => {
    return await instance.get(`${TOUR_URL}/by-sub-cat`, setParams(params));
};

export const getTourByCode = async (code) => {
    return await instance.get(`${TOUR_URL}/by-code`, setParams({ code }));
};

export const getTourPrices = async (body) => {
    return await instance.post(`${TOUR_URL}/prices`, body);
};

export const getTourFinalPrice = async (body) => {
    return await instance.post(`${GET_TOUR_FINAL_PRICE_URL}`, body);
};

export const getPaymentOptions = async (body) => {
    return await instance.post(`${GET_PAYMENT_OPTIONS_URL}`, body);
};

export const bookTour = async (body) => {
    return await instance.post(`${TOUR_URL}/book`, body);
};