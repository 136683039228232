import cultural_tours from '../assets/tour-category/cultural tours.jpg';
import outside_tours from '../assets/tour-category/Outside tours.jpg';
import ship_tours from '../assets/tour-category/ship tours.jpg';

export const hotels = [
    {
        id: 1, title: 'Yurtiçi Oteller', type: "hotels", subCategories: [
            { title: "Alanya Otelleri", count: 234 },
            { title: "Antalya Otelleri", count: 300 },
            { title: "Bodrum Otelleri", count: 58 },
            { title: "Marmaris Otelleri", count: 63 },
            { title: "Kemer Otelleri", count: 130 },
            { title: "Çeşme Otelleri", count: 34 },
            { title: "Didim Otelleri", count: 23 },
        ]
    },
    {
        id: 2, title: 'Termal Oteller', type: "hotels", subCategories: [
            { title: "Yalova Termal Otelleri", count: 234 },
            { title: "Bursa Termal Otelleri", count: 300 },
            { title: "Balıkesir Termal Otelleri", count: 58 },
            { title: "Afyon Termal Otelleri", count: 63 },
            { title: "Bolu Termal Otelleri", count: 130 },
        ]
    },
    {
        id: 3, title: 'Kıbrıs Oteller', type: "hotels", subCategories: [
            { title: "Girne Otelleri", count: 234 },
            { title: "Lefkoşa Otelleri", count: 300 },
            { title: "Magosa Otelleri", count: 58 },
            { title: "Bafra Otelleri", count: 63 },
        ]
    },
    {
        id: 4, title: 'Kış Oteller', type: "hotels", subCategories: [
            { title: "Kayak Otelleri", count: 234 },
            { title: "Uludağ Otelleri", count: 300 },
            { title: "Kartepe Otelleri", count: 58 },
            { title: "Kartalkaya Otelleri", count: 63 },
            { title: "Sarıkamış Otelleri", count: 33 },
        ]
    },
    {
        id: 5, title: 'Şehir Oteller', type: "hotels", subCategories: [
            { title: "İstanbul Otelleri", count: 677 },
            { title: "Ankara Otelleri", count: 476 },
            { title: "Izmir Otelleri", count: 346 },
            { title: "Bolu Otelleri", count: 287 },
            { title: "Afyon Otelleri", count: 187 },
            { title: "Sapanca Otelleri", count: 63 },
            { title: "Ağva Otelleri", count: 33 },
        ]
    },
    {
        id: 6, title: 'Popüler Kategoriler', type: "hotels", subCategories: [
            { title: "Her Şey Dahil Bodrum Otelleri", count: 66 },
            { title: "Her Şey Dahil Alanya Otelleri", count: 22 },
            { title: "Her Şey Dahil Antalya Otelleri", count: 12 },
            { title: "Her Şey Dahil Kuşadası Otelleri", count: 2 },
        ]
    },
];

export const tours = [
    {
        id: 1, name: 'Kültür Turları', image: cultural_tours,
        type: "tours",
        description: "Kültür Turları bilgi",
        data: [
            { title: 'Erken Reservasyon Turları', count: 100 },
            { title: 'Gap Turları', count: 34 },
            { title: 'Karadeniz Turları', count: 75 },
            { title: 'Kapadokya Turları', count: 146 },
        ]
    },
    {
        id: 2, name: 'Yurt Dışı Turları', image: outside_tours,
        type: "tours",
        description: "Yurt Dışı Turları bilgi",
        data: [
            { title: 'Balayı Turları', count: 100 },
            { title: 'Balkan Turları', count: 34 },
            { title: 'Vizesiz Turlar', count: 23 },
            { title: 'Vizeli Turlar', count: 124 },
            { title: 'İtalya Turları', count: 35 },
        ]
    },
    {
        id: 3, name: 'Gemi Turları', image: ship_tours,
        type: "tours",
        description: "Gemi Turları bilgi",
        data: [
            { title: 'Erken Reservasyon Turları', count: 100 },
            { title: 'Türkiye Çıkışlı Gemi Turları', count: 57 },
            { title: 'Akdeniz Gemi Turları', count: 34 },
        ]
    },
];

export const transfers = [
    { id: 1, type: "transfers", title: 'Araç Kiralama' },
    { id: 2, type: "transfers", title: 'Uçak' },
    { id: 3, type: "transfers", title: 'Diğer Transferler' },
];

export const tourList =
    [
        {
            "id": 1945,
            "provider": "SeturLocalTour",
            "tourType": "DomesticCulture",
            "name": "Kapadokya Turu Uçaklı",
            "image": "https://cdn2.setur.com.tr/image/room/otel-gorseli-bulunamadi.jpg",
            "url": "kapadokya-turu-2-gece-3-gun-ucakli",
            "placesToGo": "Uçhisar Kalesi, Paşabağ Vadisi, Göreme Açık Hava Müzesi, Ürgüp, Kayseri",
            "status": "Available",
            "secure": false,
            "nightStay": 2,
            "dayStay": 3,
            "hasTravelInsurence": false,
            "transportionTypeName": "Uçak ile gidiş Uçak ile dönüş",
            "transportionTypeId": 19,
            "transportionTypeCode": "UGUD",
            "departureCountryName": "Türkiye",
            "departureCountryId": 218,
            "departureCityName": "İstanbul",
            "departureCityId": 61,
            "region": "İç Anadolu",
            "categoryName": "Kültür Turları",
            "categoryId": 22,
            "subCategoryId": 0,
            "hotelBoardTypeName": "Yarım Pansiyon",
            "hotelBoardTypeId": 9,
            "installments": "9 Taksit",
            "seturExecutive": false,
            "pricing": {
                "original": 468.75,
                "selling": 16737.98,
                "discounted": 468.75,
                "rate": 0,
                "currency": "EUR"
            },
            "dates": [
                {
                    "departureDate": "2024-07-13T00:00:00Z",
                    "arrivalDate": "2024-07-15T00:00:00Z"
                },
                {
                    "departureDate": "2024-10-27T00:00:00Z",
                    "arrivalDate": "2024-10-29T00:00:00Z"
                }
            ],
            "features": [
                {
                    "key": 17,
                    "value": "Uçaklı Turlar"
                }
            ],
            "countries": [
                {
                    "key": 218,
                    "value": "Türkiye"
                }
            ],
            "cities": [
                {
                    "key": 68,
                    "value": "Kayseri"
                },
                {
                    "key": 82,
                    "value": "Nevşehir"
                }
            ],
            "departurePoints": [
                {
                    "tourDeparturePointId": 51,
                    "name": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "description": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "departureTime": "07:00"
                }
            ],
            "images": [
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-e0b3f777-7115-4790-8ee8-c1b4a1199f16.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kapadokya-turu-ucakli-valley-cappadocia_320021.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kapadokya-turu-ucakli-vadi-kapadokya_320020.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kapadokya-turu-ucakli-uchisar-kalesi_320019.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kapadokya-turu-ucakli-uchisar-castle_320018.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kapadokya-turu-ucakli-uchisar_320017.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kapadokya-turu-ucakli-uc-guzeller_320016.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kapadokya-turu-ucakli-pigeon-valley_320014.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kapadokya-turu-ucakli-pasabag-vadisi_320012.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kapadokya-turu-ucakli-kizilcukur-vadisi-agirnas-kasabasi_320011.jpg"
            ],
            "videos": [],
            "promotions": [],
            "tags": []
        },
        {
            "id": 4091,
            "provider": "SeturLocalTour",
            "tourType": "DomesticCulture",
            "name": "Ankara Çıkışlı Uçaklı Likya Turu",
            "image": "https://cdn2.setur.com.tr/image/room/otel-gorseli-bulunamadi.jpg",
            "url": "ankara-cikisli-ucakli-likya-turu",
            "placesToGo": "Fethiye, Dalyan, Ölüdeniz, Yakapark, Kaş, Kekova, Gökova",
            "status": "Available",
            "secure": false,
            "nightStay": 4,
            "dayStay": 5,
            "hasTravelInsurence": false,
            "transportionTypeName": "Uçak ile gidiş Uçak ile dönüş",
            "transportionTypeId": 19,
            "transportionTypeCode": "UGUD",
            "departureCountryName": "Türkiye",
            "departureCountryId": 218,
            "departureCityName": "Ankara",
            "departureCityId": 28,
            "region": "Ege",
            "categoryName": "Kültür Turları",
            "categoryId": 22,
            "subCategoryId": 0,
            "hotelBoardTypeName": "Yarım Pansiyon",
            "hotelBoardTypeId": 9,
            "installments": "9 Taksit",
            "seturExecutive": false,
            "pricing": {
                "original": 19000,
                "selling": 19000,
                "discounted": 19000,
                "rate": 0,
                "currency": "TL"
            },
            "dates": [
                {
                    "departureDate": "2024-06-15T00:00:00Z",
                    "arrivalDate": "2024-06-19T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-01T00:00:00Z",
                    "arrivalDate": "2024-07-05T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-08T00:00:00Z",
                    "arrivalDate": "2024-07-12T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-15T00:00:00Z",
                    "arrivalDate": "2024-07-19T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-22T00:00:00Z",
                    "arrivalDate": "2024-07-26T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-29T00:00:00Z",
                    "arrivalDate": "2024-08-02T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-05T00:00:00Z",
                    "arrivalDate": "2024-08-09T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-12T00:00:00Z",
                    "arrivalDate": "2024-08-16T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-19T00:00:00Z",
                    "arrivalDate": "2024-08-23T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-26T00:00:00Z",
                    "arrivalDate": "2024-08-30T00:00:00Z"
                },
                {
                    "departureDate": "2024-09-02T00:00:00Z",
                    "arrivalDate": "2024-09-06T00:00:00Z"
                },
                {
                    "departureDate": "2024-09-09T00:00:00Z",
                    "arrivalDate": "2024-09-13T00:00:00Z"
                }
            ],
            "features": [
                {
                    "key": 17,
                    "value": "Uçaklı Turlar"
                },
                {
                    "key": 43,
                    "value": "Yarım Pansiyon"
                }
            ],
            "countries": [
                {
                    "key": 218,
                    "value": "Türkiye"
                }
            ],
            "cities": [
                {
                    "key": 80,
                    "value": "Muğla"
                }
            ],
            "departurePoints": [
                {
                    "tourDeparturePointId": 48,
                    "name": "Ankara Esenboğa Havalimanı",
                    "description": "Ankara Esenboğa Havalimanı",
                    "departureTime": "05:45"
                }
            ],
            "images": [
                "https://cdn2.setur.com.tr/image/tour/large/sedir-adasi-kleopatra-plaji-likya-turu_328850.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kale-koy-simena-kekova-likya-turu_328844.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kekova-likya-tipi-lahit-likya-turu_328847.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/akyaka-azmak-cayi-likya-turu_328841.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/gokava-azmak-cayi-tekne-turu-likya-turu_328843.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kaya-mezari-kaunos-nekropol-likya-turu_328846.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/xanthos-antik-kenti-likya-turu_328851.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kral-aminthas-kaya-mezari-likya-turu_328848.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/saklikent-kanyon-likya-turu_328855.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/saklikent-kanyonu-likya-turu_328849.jpg"
            ],
            "videos": [],
            "promotions": [
                {
                    "id": "8c0ead92-5bd2-484b-be85-40db34543fb1",
                    "name": "KoçAilem Kültür Turu İndirimi",
                    "image": "https://cdn2.setur.com.tr/image/promotions/b2c-promotion.png",
                    "description": "KoçAilem %10 İndirim",
                    "promotionType": "TourDiscountAction"
                }
            ],
            "tags": []
        },
        {
            "id": 1410,
            "provider": "SeturLocalTour",
            "tourType": "DomesticCulture",
            "name": "Uçaklı Likya Turu",
            "image": "https://cdn2.setur.com.tr/image/room/otel-gorseli-bulunamadi.jpg",
            "url": "ucakli-likya-turu",
            "placesToGo": "Fethiye, Dalyan, Ölüdeniz, Yakapark, Kaş, Kekova, Gökova",
            "status": "Available",
            "secure": false,
            "nightStay": 4,
            "dayStay": 5,
            "hasTravelInsurence": false,
            "transportionTypeName": "Uçak ile gidiş Uçak ile dönüş",
            "transportionTypeId": 19,
            "transportionTypeCode": "UGUD",
            "departureCountryName": "Türkiye",
            "departureCountryId": 218,
            "departureCityName": "İstanbul",
            "departureCityId": 61,
            "region": "Ege",
            "categoryName": "Kültür Turları",
            "categoryId": 22,
            "subCategoryId": 0,
            "hotelBoardTypeName": "Yarım Pansiyon",
            "hotelBoardTypeId": 9,
            "installments": "9 Taksit",
            "seturExecutive": false,
            "pricing": {
                "original": 20450,
                "selling": 20450,
                "discounted": 20450,
                "rate": 0,
                "currency": "TL"
            },
            "dates": [
                {
                    "departureDate": "2024-06-15T00:00:00Z",
                    "arrivalDate": "2024-06-19T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-01T00:00:00Z",
                    "arrivalDate": "2024-07-05T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-08T00:00:00Z",
                    "arrivalDate": "2024-07-12T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-15T00:00:00Z",
                    "arrivalDate": "2024-07-19T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-22T00:00:00Z",
                    "arrivalDate": "2024-07-26T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-29T00:00:00Z",
                    "arrivalDate": "2024-08-02T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-05T00:00:00Z",
                    "arrivalDate": "2024-08-09T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-12T00:00:00Z",
                    "arrivalDate": "2024-08-16T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-19T00:00:00Z",
                    "arrivalDate": "2024-08-23T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-26T00:00:00Z",
                    "arrivalDate": "2024-08-30T00:00:00Z"
                },
                {
                    "departureDate": "2024-09-02T00:00:00Z",
                    "arrivalDate": "2024-09-06T00:00:00Z"
                },
                {
                    "departureDate": "2024-09-09T00:00:00Z",
                    "arrivalDate": "2024-09-13T00:00:00Z"
                }
            ],
            "features": [
                {
                    "key": 17,
                    "value": "Uçaklı Turlar"
                },
                {
                    "key": 43,
                    "value": "Yarım Pansiyon"
                }
            ],
            "countries": [
                {
                    "key": 218,
                    "value": "Türkiye"
                }
            ],
            "cities": [
                {
                    "key": 80,
                    "value": "Muğla"
                }
            ],
            "departurePoints": [
                {
                    "tourDeparturePointId": 51,
                    "name": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "description": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "departureTime": "05:45"
                }
            ],
            "images": [
                "https://cdn2.setur.com.tr/image/tour/large/sedir-adasi-kleopatra-plaji-likya-turu_328850.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/saklikent-kanyonu-likya-turu_328849.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/saklikent-kanyon-likya-turu_328855.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kral-aminthas-kaya-mezari-likya-turu_328848.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/xanthos-antik-kenti-likya-turu_328851.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kaya-mezari-kaunos-nekropol-likya-turu_328846.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kale-koy-simena-kekova-likya-turu_328844.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/gokava-azmak-cayi-tekne-turu-likya-turu_328843.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/akyaka-azmak-cayi-likya-turu_328841.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kekova-likya-tipi-lahit-likya-turu_328847.jpg"
            ],
            "videos": [],
            "promotions": [
                {
                    "id": "8c0ead92-5bd2-484b-be85-40db34543fb1",
                    "name": "KoçAilem Kültür Turu İndirimi",
                    "image": "https://cdn2.setur.com.tr/image/promotions/b2c-promotion.png",
                    "description": "KoçAilem %10 İndirim",
                    "promotionType": "TourDiscountAction"
                }
            ],
            "tags": []
        },
        {
            "id": 1951,
            "provider": "SeturLocalTour",
            "tourType": "DomesticCulture",
            "name": "İstanbul Çıkışlı Uçaklı Gap Turu",
            "image": "https://cdn2.setur.com.tr/image/room/otel-gorseli-bulunamadi.jpg",
            "url": "istanbul-cikisli-ucakli-gap-turu",
            "placesToGo": "Gaziantep, Şanlıurfa, Mardin, Diyarbakır",
            "status": "Available",
            "secure": false,
            "nightStay": 4,
            "dayStay": 5,
            "hasTravelInsurence": false,
            "transportionTypeName": "Uçak ile gidiş Uçak ile dönüş",
            "transportionTypeId": 19,
            "transportionTypeCode": "UGUD",
            "departureCountryName": "Türkiye",
            "departureCountryId": 218,
            "departureCityName": "İstanbul",
            "departureCityId": 61,
            "region": "Doğu Anadolu",
            "categoryName": "Kültür Turları",
            "categoryId": 22,
            "subCategoryId": 0,
            "hotelBoardTypeName": "Yarım Pansiyon",
            "hotelBoardTypeId": 9,
            "installments": "9 Taksit",
            "seturExecutive": false,
            "pricing": {
                "original": 20875,
                "selling": 20875,
                "discounted": 20875,
                "rate": 0,
                "currency": "TL"
            },
            "dates": [
                {
                    "departureDate": "2024-06-15T00:00:00Z",
                    "arrivalDate": "2024-06-19T00:00:00Z"
                }
            ],
            "features": [
                {
                    "key": 16,
                    "value": "Türkçe Rehberli"
                },
                {
                    "key": 17,
                    "value": "Uçaklı Turlar"
                },
                {
                    "key": 43,
                    "value": "Yarım Pansiyon"
                }
            ],
            "countries": [
                {
                    "key": 218,
                    "value": "Türkiye"
                }
            ],
            "cities": [
                {
                    "key": 54,
                    "value": "Gaziantep"
                },
                {
                    "key": 23,
                    "value": "Adıyaman"
                },
                {
                    "key": 89,
                    "value": "Şanlıurfa"
                },
                {
                    "key": 78,
                    "value": "Mardin"
                },
                {
                    "key": 47,
                    "value": "Diyarbakır"
                }
            ],
            "departurePoints": [
                {
                    "tourDeparturePointId": 51,
                    "name": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "description": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "departureTime": "04:45"
                }
            ],
            "images": [
                "https://cdn2.setur.com.tr/image/tour/large/gaziantep-cingene-kizi-zeugma-muzesi_409383.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/gaziantep-mozaik-muzesi_409384.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/gaziantep-adiyaman-sanliurfa-turu-rum-kale_409391.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/gaziantep-adiyaman-sanliurfa-turu-cendere--koprusu_409385.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/bakircilar-carsisi-gaziantep_409382.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-f3a2acbe-a30f-40e8-890e-d127bf54c6f2.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-10abeea0-5d52-4ffa-877b-6ae14aeeb57c.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-f09ac0a2-f14d-48b9-9d5b-30f2583bec95.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-c2c760a2-5410-420b-8620-0621be03e2a8.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-6f9ef206-3111-4ba4-a587-dff9c569028f.jpg"
            ],
            "videos": [],
            "promotions": [],
            "tags": []
        },
        {
            "id": 1948,
            "provider": "SeturLocalTour",
            "tourType": "DomesticCulture",
            "name": "Butik Doğu Karadeniz Yaylaları Turu",
            "image": "https://cdn2.setur.com.tr/image/room/otel-gorseli-bulunamadi.jpg",
            "url": "butik-dogu-karadeniz-yaylalari-turu",
            "placesToGo": "Kars, Şavşat, Borçka, Karagöl, Mençuna Şelalesi, Ayder, Avusor Yaylası, Huser Yaylası, Pokut Yaylası, Sal Yaylası, Zil Kale,  Çat Vadisi, Çiçekli Yayla",
            "status": "Available",
            "secure": false,
            "nightStay": 4,
            "dayStay": 5,
            "hasTravelInsurence": false,
            "transportionTypeName": "Uçak ile gidiş Uçak ile dönüş",
            "transportionTypeId": 19,
            "transportionTypeCode": "UGUD",
            "departureCountryName": "Türkiye",
            "departureCountryId": 218,
            "departureCityName": "İstanbul",
            "departureCityId": 61,
            "region": "Karadeniz",
            "categoryName": "Kültür Turları",
            "categoryId": 22,
            "subCategoryId": 0,
            "hotelBoardTypeName": "Yarım Pansiyon",
            "hotelBoardTypeId": 9,
            "installments": "9 Taksit",
            "seturExecutive": false,
            "pricing": {
                "original": 22050,
                "selling": 22050,
                "discounted": 22050,
                "rate": 0,
                "currency": "TL"
            },
            "dates": [
                {
                    "departureDate": "2024-06-15T00:00:00Z",
                    "arrivalDate": "2024-06-19T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-15T00:00:00Z",
                    "arrivalDate": "2024-07-19T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-22T00:00:00Z",
                    "arrivalDate": "2024-07-26T00:00:00Z"
                },
                {
                    "departureDate": "2024-07-29T00:00:00Z",
                    "arrivalDate": "2024-08-02T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-05T00:00:00Z",
                    "arrivalDate": "2024-08-09T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-12T00:00:00Z",
                    "arrivalDate": "2024-08-16T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-19T00:00:00Z",
                    "arrivalDate": "2024-08-23T00:00:00Z"
                },
                {
                    "departureDate": "2024-08-26T00:00:00Z",
                    "arrivalDate": "2024-08-30T00:00:00Z"
                },
                {
                    "departureDate": "2024-09-30T00:00:00Z",
                    "arrivalDate": "2024-10-04T00:00:00Z"
                }
            ],
            "features": [
                {
                    "key": 17,
                    "value": "Uçaklı Turlar"
                }
            ],
            "countries": [
                {
                    "key": 218,
                    "value": "Türkiye"
                }
            ],
            "cities": [
                {
                    "key": 31,
                    "value": "Artvin"
                },
                {
                    "key": 66,
                    "value": "Kars"
                },
                {
                    "key": 86,
                    "value": "Rize"
                }
            ],
            "departurePoints": [
                {
                    "tourDeparturePointId": 51,
                    "name": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "description": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "departureTime": "05:00"
                }
            ],
            "images": [
                "https://cdn2.setur.com.tr/image/tour/large/dogu-karadeniz-yayla-turu-ayder-yaylasi_417519.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/pokut-yaylasi-yayla-turu_417506.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/sal-yaylasi_417507.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/savsat-karagol_417511.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/pokut-yaylasi-butik-karadeniz-yayla-turu_417505.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/savsat-karagol-butik-dogu-karadeniz-turu_417510.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/mencuna-selalesi-dogu-karadeniz_417514.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/pokut-yaylasi_417520.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/avusor-yaylasi-butik-dogu-karadeniz-yayla-turu_417509.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/dogu-karadeniz--yaylalari-turu-ayder-yaylasi_417518.jpg"
            ],
            "videos": [],
            "promotions": [
                {
                    "id": "8c0ead92-5bd2-484b-be85-40db34543fb1",
                    "name": "KoçAilem Kültür Turu İndirimi",
                    "image": "https://cdn2.setur.com.tr/image/promotions/b2c-promotion.png",
                    "description": "KoçAilem %10 İndirim",
                    "promotionType": "TourDiscountAction"
                }
            ],
            "tags": []
        },
        {
            "id": 1407,
            "provider": "SeturLocalTour",
            "tourType": "DomesticCulture",
            "name": "Isparta Lavanta ve Göller Yöresi Turu",
            "image": "https://cdn2.setur.com.tr/image/room/otel-gorseli-bulunamadi.jpg",
            "url": "isparta-lavanta-ve-goller-yoresi-turu",
            "placesToGo": "Eğirdir, Kovada Gölü, Yazılı Kanyon, Salda Gölü, Sagalassos Antik Kenti, Burdur",
            "status": 0,
            "secure": false,
            "nightStay": 2,
            "dayStay": 3,
            "hasTravelInsurence": false,
            "transportionTypeName": "Uçak ile gidiş Uçak ile dönüş",
            "transportionTypeId": 19,
            "transportionTypeCode": "UGUD",
            "departureCountryName": "Türkiye",
            "departureCountryId": 218,
            "departureCityName": "İstanbul",
            "departureCityId": 61,
            "region": "Akdeniz",
            "categoryName": "Kültür Turları",
            "categoryId": 22,
            "subCategoryId": 0,
            "hotelBoardTypeName": "Yarım Pansiyon",
            "hotelBoardTypeId": 9,
            "installments": "9 Taksit",
            "seturExecutive": false,
            "pricing": {},
            "dates": [],
            "features": [
                {
                    "key": 17,
                    "value": "Uçaklı Turlar"
                },
                {
                    "key": 43,
                    "value": "Yarım Pansiyon"
                }
            ],
            "countries": [
                {
                    "key": 218,
                    "value": "Türkiye"
                }
            ],
            "cities": [
                {
                    "key": 41,
                    "value": "Burdur"
                },
                {
                    "key": 60,
                    "value": "Isparta"
                }
            ],
            "departurePoints": [
                {
                    "tourDeparturePointId": 30,
                    "name": "İstanbul Havalimanı",
                    "description": "İstanbul Havalimanı",
                    "departureTime": "06:45"
                }
            ],
            "images": [
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-levander-kuyucak_246266.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-levander-kuyucak-mor-bahce_246268.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-levander-kuyucak-lavanta-bali_246267.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-levander_246265.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-hasat-turu-14-16-temmuz-2017-sagalassos-antik-kenti_246275.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-hasat-turu-14-16-temmuz-2017-antoninler-cesmesi_246274.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-hasat-turu-14-16-temmuz-2017-salda-golu_246272.jpg"
            ],
            "videos": [],
            "promotions": [],
            "tags": []
        },
        {
            "id": 1950,
            "provider": "SeturLocalTour",
            "tourType": "DomesticCulture",
            "name": "Tunceli Kemaliye Turu",
            "image": "https://cdn2.setur.com.tr/image/room/otel-gorseli-bulunamadi.jpg",
            "url": "tunceli-kemaliye-turu",
            "placesToGo": "Elazığ, Tunceli, Cem Evi, Munzur Vadisi Milli Parkı, Ovacık, Munzur Gözeleri, Hozat, Çemişgezek, Kemaliye, Karanlık Kanyon, Tekne Turu, Taş Yol, Kemaliye Doğa Tarihi Müzesi, Apçağa Köyü, Ta Tu Ta Ekolojik Çiftlik, Manili Yol, Lökhane, Kemaliye, Yeşilyurt Köyü, Divriği, Kangal Çiftliği, Sivas, Gök Medrese, Ulu Cami, Çifte Minareli Medrese, Buruciye Medresesi",
            "status": 0,
            "secure": false,
            "nightStay": 3,
            "dayStay": 4,
            "hasTravelInsurence": false,
            "transportionTypeName": "Uçak ile gidiş Uçak ile dönüş",
            "transportionTypeId": 19,
            "transportionTypeCode": "UGUD",
            "departureCountryName": "Türkiye",
            "departureCountryId": 218,
            "departureCityName": "İstanbul",
            "departureCityId": 61,
            "region": "Doğu Anadolu",
            "categoryName": "Kültür Turları",
            "categoryId": 22,
            "subCategoryId": 0,
            "hotelBoardTypeName": "Yarım Pansiyon",
            "hotelBoardTypeId": 9,
            "installments": "9 Taksit",
            "seturExecutive": false,
            "pricing": {},
            "dates": [],
            "features": [
                {
                    "key": 16,
                    "value": "Türkçe Rehberli"
                }
            ],
            "countries": [
                {
                    "key": 218,
                    "value": "Türkiye"
                }
            ],
            "cities": [
                {
                    "key": 97,
                    "value": "Tunceli"
                },
                {
                    "key": 51,
                    "value": "Erzincan"
                },
                {
                    "key": 76,
                    "value": "Malatya"
                }
            ],
            "departurePoints": [
                {
                    "tourDeparturePointId": 51,
                    "name": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "description": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "departureTime": "04:30"
                }
            ],
            "images": [
                "https://cdn2.setur.com.tr/image/tour/large/tunceli-munzur-vadisi_417099.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/karanlik-kanyon-kemaliye-turu_416931.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kemaliye-karanlik-kanyon_416932.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-9cf01a11-8db5-4041-991c-95c043003152.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-7791b4d1-aa69-4397-add2-6e88eb68f037.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-e9fcf821-0e50-4d0f-ab4b-b901a2c25f12.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-f462cce4-713a-4110-95db-fc2a43dd6a45.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-2f4fa351-251e-4329-b2e1-d5feaeb881dc.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-1e58201a-8dff-4a4b-875b-90253f49770c.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-9273cc50-c047-4458-8d34-420d5ad7bce8.jpg"
            ],
            "videos": [],
            "promotions": [],
            "tags": []
        },
        {
            "id": 1964,
            "provider": "SeturLocalTour",
            "tourType": "DomesticCulture",
            "name": "Isparta Gül Hasadı ve Göller Yöresi Turu",
            "image": "https://cdn2.setur.com.tr/image/room/otel-gorseli-bulunamadi.jpg",
            "url": "isparta-gul-hasadi-ve-goller-yoresi-turu",
            "placesToGo": "Eğirdir, Kovada Gölü, Yazılı Kanyon, Salda Gölü, Sagalassos Antik Kenti, Burdur, Güneykent,  Isparta",
            "status": 0,
            "secure": false,
            "nightStay": 2,
            "dayStay": 3,
            "hasTravelInsurence": false,
            "transportionTypeName": "Uçak ile gidiş Uçak ile dönüş",
            "transportionTypeId": 19,
            "transportionTypeCode": "UGUD",
            "departureCountryName": "Türkiye",
            "departureCountryId": 218,
            "departureCityName": "İstanbul",
            "departureCityId": 61,
            "region": "Akdeniz",
            "categoryName": "Kültür Turları",
            "categoryId": 22,
            "subCategoryId": 0,
            "hotelBoardTypeName": "Yarım Pansiyon",
            "hotelBoardTypeId": 9,
            "installments": "9 Taksit",
            "seturExecutive": false,
            "pricing": {},
            "dates": [],
            "features": [
                {
                    "key": 17,
                    "value": "Uçaklı Turlar"
                },
                {
                    "key": 43,
                    "value": "Yarım Pansiyon"
                }
            ],
            "countries": [
                {
                    "key": 218,
                    "value": "Türkiye"
                }
            ],
            "cities": [
                {
                    "key": 60,
                    "value": "Isparta"
                },
                {
                    "key": 41,
                    "value": "Burdur"
                }
            ],
            "departurePoints": [
                {
                    "tourDeparturePointId": 30,
                    "name": "İstanbul Havalimanı",
                    "description": "İstanbul Havalimanı",
                    "departureTime": "06:45"
                }
            ],
            "images": [
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-d5c71edc-e778-49b3-9ef9-e97c30dc0c96.png",
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-hasat-turu-14-16-temmuz-2017-antoninler-cesmesi_285771.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-hasat-turu-14-16-temmuz-2017-sagalassos-antik-kenti_285772.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/isparta-lavanta-hasat-turu-14-16-temmuz-2017-salda-golu_285770.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-5bd13a2f-a0b5-4b89-8d01-ae76be113ec3.png",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-c9b6d63d-2814-4dda-996e-0c7b0183daca.png",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-7ba7590f-eaba-4a7a-99a9-d82e6b181314.png",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-00fcdd69-d5cf-4ff0-862c-1464ac708a09.png",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-85b332d7-b4dd-46f2-9fb6-00aa04eb18d4.png",
                "https://cdn2.setur.com.tr/image/tour/large/kultur-yurt-ici-paket-turlar-97dc1f3a-531f-4dd5-9e83-e5e8e68e8434.jpg"
            ],
            "videos": [],
            "promotions": [],
            "tags": []
        },
        {
            "id": 2257,
            "provider": "SeturLocalTour",
            "tourType": "DomesticCulture",
            "name": "Kars Çıldır Erzurum Turu",
            "image": "https://cdn2.setur.com.tr/image/room/otel-gorseli-bulunamadi.jpg",
            "url": "kars-cildir-erzurum-turu",
            "placesToGo": "Kars, Çıldır Gölü, Ani Ören Yeri, Sarıkamış, Erzurum Fiyatlarımıza Akşam Yemeği, Kafkas Halk Dans Gösterisi ve Müze ve Ören Yeri Giriş Ücretleri Dahildir.",
            "status": 0,
            "secure": false,
            "nightStay": 2,
            "dayStay": 3,
            "hasTravelInsurence": false,
            "transportionTypeName": "Uçak ile gidiş Uçak ile dönüş",
            "transportionTypeId": 19,
            "transportionTypeCode": "UGUD",
            "departureCountryName": "Türkiye",
            "departureCountryId": 218,
            "departureCityName": "İstanbul",
            "departureCityId": 61,
            "region": "Doğu Anadolu",
            "categoryName": "Kültür Turları",
            "categoryId": 22,
            "subCategoryId": 0,
            "hotelBoardTypeName": "Yarım Pansiyon",
            "hotelBoardTypeId": 9,
            "installments": "9 Taksit",
            "seturExecutive": false,
            "pricing": {},
            "dates": [],
            "features": [
                {
                    "key": 17,
                    "value": "Uçaklı Turlar"
                },
                {
                    "key": 58,
                    "value": "Kafkas Halk Dans Gösterisi"
                }
            ],
            "countries": [
                {
                    "key": 218,
                    "value": "Türkiye"
                }
            ],
            "cities": [
                {
                    "key": 66,
                    "value": "Kars"
                },
                {
                    "key": 30,
                    "value": "Ardahan"
                },
                {
                    "key": 52,
                    "value": "Erzurum"
                }
            ],
            "departurePoints": [
                {
                    "tourDeparturePointId": 51,
                    "name": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "description": "Sabiha Gökçen Havalimanı (İç Hatlar Gidiş)Buluşma",
                    "departureTime": "06:30"
                }
            ],
            "images": [
                "https://cdn2.setur.com.tr/image/tour/large/shutterstock218879665_284186.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/shutterstock5989729_284187.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/shutterstock234847171_284191.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/shutterstock219952546_284190.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/shutterstock174625184_284189.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/katerina-2_314985.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/katerina_314986.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kars-sedventure204972_284192.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/kars-kafkas-dans_284193.jpg",
                "https://cdn2.setur.com.tr/image/tour/large/shutterstock126789686_284188.jpg"
            ],
            "videos": [],
            "promotions": [],
            "tags": []
        }
    ]