import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Carousel, Loader, IconButton, } from 'rsuite';
import './main_case.scss';
import SearchBar from './SearchBar';
import balkan_img from '../assets/carousel/balkans.png';
import italy_img from '../assets/carousel/italya.png';
import lodra_img from '../assets/carousel/londra.png';
import Gateway, { publicRoutes } from './Gateway';
import Login from './auth/Login';
import NavBar from '../components/navbar/NavBar';
import Footer from '../components/footer/Footer';
import ArrowUpIcon from '@rsuite/icons/ArrowUp';
import { LANGUAGES } from '../utils/constants';
import { getStorageItem, setStorageItem } from '../utils/helpers';
import { Route, Routes, useLocation } from 'react-router-dom';
import { getCategoriesAction } from './content/category/redux/actions';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { getToursAction } from './content/tours/redux/actions';


function Main(props) {
    const [showScrollButton, setShowScrollButton] = useState(false);
    const isLangInitialized = getStorageItem("lang");
    const location = useLocation();

    if (!isLangInitialized) {
        setStorageItem("lang", LANGUAGES[1]);
    }

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };

    const scrollToTop = () => {
        const navbarElement = document.getElementById('scroll_to');
        if (navbarElement) {
            navbarElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isCarouselDisplay = useCallback(() => {
        const listOfPathnames = ["/detail/", "/book/", "/SSS", "/about", "/communication", "/privacy-police", "/kvvk"];
    
        // Check if the current pathname starts with any of the specified values
        const isExcludedPath = listOfPathnames.some(path => location?.pathname.includes(path));
    
        return !isExcludedPath;
    }, [location]);


    useEffect(() => {
        props.getToursAction({ highlighted: 1, page: 1, limit: 20 });
    }, []);

    return (
        <div className='main'>
            <NavBar idForScroll={!isCarouselDisplay() ? "scroll_to" : undefined} />
            <Container>
                {isCarouselDisplay() && (
                    <Container className='main_ttl'>
                        <Carousel autoplay shape={"none"} className="custom-slider" id="scroll_to">
                            {props.tours_data?.data?.length > 0 && props.tours_data.data.filter(el => el.thumbnail).map((tour, index) => (
                                <img
                                    key={index}
                                    src={tour.thumbnail}
                                    height="100%"
                                />
                            ))}
                        </Carousel>
                        <SearchBar />
                    </Container>
                )}
                <Container className='main_lst'>
                    <div className='content'>
                        <Routes>
                            {publicRoutes.filter(item => !item.isAuthNeeded)
                                .map((val, key) => <Route key={key} exact path={val?.path} element={val?.element} />)
                            }
                        </Routes>
                    </div>
                </Container>
            </Container>
            <Container className='main_footer'>
                <div className='content'>
                    <Footer />
                </div>
            </Container>
            {showScrollButton && (
                <IconButton icon={<ArrowUpIcon />} className="scroll-to-top" onClick={scrollToTop} />
            )}
        </div >
    );
}
const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    common_error: state.commonReducer.common_error,
    tours_data: state.tourReducer.tours_data
});

const mapDispatchToProps = dispatch => ({
    getToursAction: (queryParams) => dispatch(getToursAction(queryParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
